.serviceReadMore {
  position: absolute;
  bottom: 0;left: 0;
  background: rgba(38,68,109,1);
  padding: 15px 45px;
  color: #fff;
  height: 50px;
  border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;
  text-align: center;
  width: 100%;
  }
  .serviceReadMore a {
      color: #fff;
      text-transform: uppercase;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 1em;
      position: relative;
      display: block;
      transition: all 0.3s ease-in;
  }
  .serviceReadMore a::after {
      content: '\f0da';
      font-family: FontAwesome;
      padding-left: 10px;
  }
  .about-us{
   background: url(./wp-content/uploads/2018/07/aboutBanner.png) no-repeat center center;background-size: cover
  }
  .serviceFlex h3 { 
    font-size: 24px;
    color: #444444;
    line-height: 1em;
    margin: 1em 0 0.7em 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    }
    .serviceFlex p {
        color: #444444;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0.6em;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
    }
    .serviceFlex ol  {
        padding: 0.2em 36px;
        margin: 0;
        list-style: disc;
    }
    .serviceFlex  ul {
        padding: 0.2em 36px;
        margin: 0;
        list-style: disc;
    }
    .serviceFlex li {
        color: #444;
        font-size: 16px;
        line-height: 1.4em;
        margin-bottom: 0.6em;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
    }
    .requestConsultation{
        background: url('./wp-content/uploads/2018/07/requstConsultBg.png') no-repeat center top
    }
    .countingSec{
        background: #113362  url('./wp-content/uploads/2018/07/countingBg.png') no-repeat center top;
         background-size:cover;
    }
    .navbar-light .navbar-nav .nav-link {
      
        font-size: 16px;
        line-height: 1em;
        color: #444444!important;
        font-family: 'Lato', sans-serif;
        font-weight: 700!important;
        transition: all 0.4s ease-in;
    }
   
    .bg-light {
        background-color: #f8f9fa!important;
        margin-bottom: 0!important;
    }
    @media (min-width: 420px){
        .navbar-nav{
        
            margin-left: 0px!important;
        
            }
            .navbar-brand{
                margin-left: 0!important;
            }
            .bg-light{
                padding-left: 5px;
            }
    }
    @media (min-width: 1024px){
    
        .navbar-nav{
        
            margin-left: 267px!important;
        
            }
            .navbar-brand{
                margin-left: 80px!important;
            }
    
    }
    @media (min-width: 1300px){
        .navbar-nav{
        
            margin-left: 441px!important;
        
            }
            .navbar-brand{
                margin-left: 130px!important;
            }
    }
    @media (min-width: 1700px){
        .navbar-nav{
        
            margin-left: 647px!important;
        
            }
            .navbar-brand{
                margin-left: 500px!important;
            }
    }